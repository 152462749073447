export const environment = {
    firebase: {
        options: {
            apiKey: 'AIzaSyDpqktdOQyijnyCaiaOl3_DxUQhTu3PjUg',
            authDomain: 'beeoclock-develop.firebaseapp.com',
            projectId: 'beeoclock-develop',
            storageBucket: 'beeoclock-develop.appspot.com',
            messagingSenderId: '957741407419',
            appId: '1:957741407419:web:cfa84b1e0ac458dfaa5d9e',
        },
        emulator: {
            all: false,
            authorization: false,
        },
    },
    production: false,
    emulator: false,
    apiUrls: {
        client: 'https://api.dev.beeoclock.com/client',
        identity: 'https://api.dev.beeoclock.com/identity',
    },
    endpoint: {
        config: {
            replace: false,
            loading: false,
            defaultErrorHandler: true,
        },
    },
    config: {
        language: 'en',
        modal: {
            prefix: 'beeoclock_modal_',
        },
        slots: {
            selectDay: {
                step: {
                    days: 5,
                },
            },
        },
    },
    redirect: {
        notFound: 'https://dev.beeoclock.biz/',
    },
    url: {
        self: 'https://dev.beeoclock.com',
    },
    socialNetwork: {
        link: {
            facebook: 'https://www.facebook.com/beeoclock.biz',
            instagram: 'https://www.instagram.com/beeoclock.biz',
            x: 'https://www.x.com/bee_o_clock',
        },
        username: {
            facebook: 'beeoclock.biz',
            instagram: 'beeoclock.biz',
            x: 'bee_o_clock',
        },
    },
    ai: {
        openai: {
            key: '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',
            model: 'gpt-3.5-turbo'
        },
    }
};
